<template>
  <v-theme-provider dark>
    <section id="solutions-main">
      <base-img
        :height="$vuetify.breakpoint.mdAndUp ? 400 : 225"
        gradient="to bottom, rgba(11, 28, 61, .20), rgba(0,0,0,.4)"
        :src="require('@/assets/solutions_main.jpg')"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-row
          align="center"
          class="ma-0 fill-height text-center"
          justify="center"
        >
          <v-col cols="12">
            <base-heading
              title="SOLUTIONS"
              space="2"
              weight="500"
            />
            <base-divider
              color="primary"
              dense
            />
            <v-tabs
              centered
              background-color="transparent"
              show-arrows
            >
              <v-tab
                class="text-capitalize"
                to="#smart-factory-solutions"
              >
                Automation Solution
              </v-tab>
              <v-tab
                class="text-capitalize"
                to="#it-solutions"
              >
                IT Solution
              </v-tab>
              <v-tab
                class="text-capitalize"
                to="#consulting-engineering"
              >
                Consulting·Engineering
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </base-img>
    </section>
  </v-theme-provider>
</template>

<script>
  // Components
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  export default {
    name: 'SectionSolutionsMain',

    metaInfo () {
      return {
        changed: meta => (this.title = meta.titleChunk.toUpperCase()),
      }
    },

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      title: 'Corporate Social Responsibility',
      interval: 0,
    }),

    computed: {
      gradient () {
        const color = `${this.$vuetify.theme.themes.light.secondary}CC`
        const overlay = RGBAtoCSS(HexToRGBA(color))

        return `to top, ${overlay}, ${overlay}`
      },
      src () {
        return this.$route.meta.src
      },
    },
  }
</script>

<style lang="sass">
  #hero-alt
    .v-breadcrumbs__item
      color: #FFFFFF
</style>
